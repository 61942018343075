import { getEntityclassByExternalId } from '@/repository/system.js';
import { loadCompanies } from '@/repository/bml';

const ENTITY_CLASS_ID = 'company';

const state = {
	entityClassId: '',
};

const actions = {
	async _setEntityClassId({ state, commit }) {
		if (!state.entityClassId) {
			const entityClass = await getEntityclassByExternalId(ENTITY_CLASS_ID);
			commit('setEcId', entityClass.id);
		}
	},

	async loadByIds(_, ids) {
		const data = await loadCompanies(ids);
		return data;
	},
};

const mutations = {
	setEcId(state, ecId) {
		state.entityClassId = ecId;
	},
};

export default {
	namespaced: true,
	state,
	actions,
	mutations,
};
