import Vue from 'vue';
import VueRouter from 'vue-router';
import PublicSearch from '@/views/PublicSearch.vue';
import SingleSearchResult from '@/views/SingleSearchResult';
import UserCenter from '@/views/UserCenter';
import AcceptInvite from '@/views/Registration/AcceptInvite.vue';
import AcceptTos from '@/views/Registration/AcceptTos.vue';
import Registration from '@/views/Registration/Registration.vue';

Vue.use(VueRouter);

const routes = [
	{
		path: '/',
		name: 'PublicSearch',
		component: PublicSearch,
	},
	{
		path: '/result/:product_id/:list_id/:locale?/:date?',
		name: 'SingleSearchResult',
		component: SingleSearchResult,
	},
	{
		path: '/user',
		name: 'UserCenter',
		component: UserCenter,
	},
	{
		path: '/user/registration',
		name: 'Registration',
		component: Registration,
	},
	{
		path: '/user/acceptinvite',
		name: 'Invite',
		component: AcceptInvite,
	},
	{
		path: '/user/accepttos',
		name: 'AcceptTos',
		component: AcceptTos,
	},
	{
		path: '*',
		name: 'CatchAll',
		component: PublicSearch,
	},
];

const router = new VueRouter({
	routes,
});

export default router;
