import token from './auth/token.js';
import user from './auth/user.js';

export default {
	namespaced: true,
	modules: {
		token,
		user,
	},
};
