import Store from '@/store/index';
import { fieldToLocale, NO_RESULT, toSortable } from '@/util/locale';
import { WKST_FIELD, TA_FIELD, PSM_FIELD } from '@/store/field';

export class SearchOptionsProvider {
	static async initialize() {
		await Promise.all([
			Store.dispatch('list/loadAll', { ifEmpty: true }),
			Store.dispatch('field/loadWkst'),
			Store.dispatch('field/loadTaS1Field'),
			Store.dispatch('field/loadPSMField'),
			Store.dispatch('biostandard/loadAll', { ifEmpty: true }),
		]);
	}

	static async initializeTree({ type }) {
		await Store.dispatch(`${type}/ensureRootsLoaded`, { type });
	}

	static getLocaleOptions() {
		return Store.getters['locale/getLanguagesForSelect'];
	}

	static getListOptions() {
		return Store.getters['list/lists'];
	}

	static getListOptionsUnfiltered() {
		return Store.getters['list/listsUnfiltered'];
	}

	static getStandardOptions() {
		return Store.getters['biostandard/all'].filter((f) => {
			const title = fieldToLocale(f.data.publicName);
			return title && title.trim() !== '' && title.trim() !== NO_RESULT;
		});
	}

	static getSubstanceOptions() {
		return Store.getters['field/optionsByFieldPath'](WKST_FIELD)
			.slice(0)
			.sort((a, b) => {
				let va = toSortable(fieldToLocale(a.title));
				let vb = toSortable(fieldToLocale(b.title));
				return va > vb ? 1 : -1;
			});
	}

	static getPsmCultureOptions() {
		return Store.getters['field/optionsByFieldPath'](PSM_FIELD)
			.slice(0)
			.sort((a, b) => {
				let va = toSortable(fieldToLocale(a.title));
				let vb = toSortable(fieldToLocale(b.title));
				return va > vb ? 1 : -1;
			});
	}

	static getFilteredAnimalKindsOptions(selectedAnimalKinds) {
		let options = Store.getters['field/getFilteredAnimalKindsOptions'] || [];
		const animalKindsOptions = Store.getters['field/optionsByFieldPath'](TA_FIELD);
		const animalKindsGroupsOptions = Store.getters['field/getOptionGroup'](TA_FIELD);
		options = options
			.slice(0)
			.map((option) => {
				if (selectedAnimalKinds === option.key) return true;
				return animalKindsOptions.find((PsmCultureOption) => {
					return PsmCultureOption.id === option.key;
				});
			})
			.filter((o) => !!o);
		if (selectedAnimalKinds) {
			let exists = options.find((o) => o.id === selectedAnimalKinds);
			let opt = animalKindsOptions.find((o) => o.id === selectedAnimalKinds);
			if (!exists) options.unshift(opt);
		}
		options = options.filter((opt) => !!opt);
		let resultMap = new Map();
		const filteredOptions = animalKindsOptions.filter((animalOpt) =>
			options.some((opt) => opt.id === animalOpt.id),
		);
		animalKindsGroupsOptions.map((groupOpt) => {
			let matches = filteredOptions.filter((opt) => opt.group === groupOpt.id);
			resultMap.set(groupOpt.id, matches);
		});
		options = [];
		for (let map of resultMap) {
			if (map[1].length > 0) {
				let requiredObject = {
					animalType: '',
					animalKinds: [
						{
							name: '',
							id: '',
						},
					],
				};
				const reqGroupOpt = animalKindsGroupsOptions.filter((opt) => opt.id === map[0]);
				requiredObject.animalType = fieldToLocale(reqGroupOpt[0]['title']);
				let animalKinds = [];
				map[1].map((opt) => {
					let kind = {
						name: '',
						id: '',
					};
					kind.id = opt.id;
					kind.name = fieldToLocale(opt['title']);
					animalKinds.push(kind);
				});
				animalKinds.sort((a, b) => {
					return a.name.localeCompare(b.name);
				});
				requiredObject.animalKinds = animalKinds;
				options.push(requiredObject);
			}
		}
		return options;
	}

	static getFilteredPsmCultureOptions(selectedPsmCulture) {
		let options = Store.getters['field/getFilteredPsmCultureOptions'] || [];
		const PsmCultureOptions = Store.getters['field/optionsByFieldPath'](PSM_FIELD);
		options = options
			.slice(0)
			.map((option) => {
				if (selectedPsmCulture === option.key) return true;
				return PsmCultureOptions.find((PsmCultureOption) => {
					return PsmCultureOption.id === option.key;
				});
			})
			.filter((o) => !!o);
		if (selectedPsmCulture) {
			let exists = options.find((o) => o.id === selectedPsmCulture);
			let opt = PsmCultureOptions.find((o) => o.id === selectedPsmCulture);
			if (!exists) options.unshift(opt);
		}
		options = options.filter((opt) => !!opt);
		return this.createSelectListFromArray(options, false, 'title');
	}

	static getFilteredSubstanceOptions(selectedSubstance) {
		let options = Store.getters['field/getFilteredSubstanceOptions'] || [];
		const substanceOptions = Store.getters['field/optionsByFieldPath'](WKST_FIELD);
		options = options
			.slice(0)
			.map((option) => {
				if (selectedSubstance === option.key) return true;
				return substanceOptions.find((substanceOption) => {
					return substanceOption.id === option.key;
				});
			})
			.filter((o) => !!o);
		if (selectedSubstance) {
			let exists = options.find((o) => o.id === selectedSubstance);
			let opt = substanceOptions.find((o) => o.id === selectedSubstance);
			if (!exists) options.unshift(opt);
		}
		options = options.filter((opt) => !!opt);
		return this.createSelectListFromArray(options, false, 'title');
	}

	static createSelectListFromArray(data, useDataSubObject, field = 'title') {
		if (!data || !Array.isArray(data)) {
			return [];
		}

		return data.map((obj) => {
			return {
				value: obj.id,
				text: useDataSubObject ? fieldToLocale(obj.data[field]) : fieldToLocale(obj[field]),
			};
		});
	}
}
