import * as Common from './tree-common';

const state = {
	entries: [],
	counts: {},
	entityclassId: null,
};

const getters = {
	byKey: (state) => (key) => {
		return state.entries.find((r) => r.data.mainCategoryKey === key);
	},

	byId: (state) => (id) => {
		return state.entries.find((r) => r.id === id);
	},

	byIds: (state) => (categoryIds) => {
		return state.entries.filter((r) => categoryIds.includes(r.id));
	},

	mainCategoryFromIds: (state, getters) => (totalEntries) => {
		return getters
			.byIds(totalEntries)
			.find((e) => e && !e.data.parent && e.data.mainCategoryKey);
	},

	pathToRoot: (state, getters) => (ids) => {
		return Common.pathToRoot(getters.byId, ids);
	},

	byParent: (state) => (id) => {
		return Common.getEntryByParent(state.entries, id);
	},

	roots: (state) => {
		return state.entries
			.filter((r) => !r.data.parent)
			.sort((a, b) => {
				return a.data.order < b.data.order ? -1 : 1;
			});
	},

	numberString: (state, getters) => (id) => {
		let numberString = '';
		if (!id) {
			return '';
		}
		let result = getters.pathToRoot([id]);
		if (!result) return '';
		result = result.reverse();
		let index = 0;
		while (result[index]) {
			let category = getters.byId(result[index]);
			numberString = numberString
				? `${numberString}-${category.data.order}`
				: category.data.order;
			index++;
		}
		return numberString;
	},

	all: (state) => {
		return state.entries;
	},

	countById: (state) => (id) => {
		return state.counts[id] || 0;
	},
};

const actions = {
	loadEntitesById({ commit }, ids) {
		return Common.loadEntitesById({ commit }, ids);
	},

	loadParentWithChildren({ commit, state }, { children, type }) {
		return Common.loadParentWithChildren({ commit, state }, { children, type });
	},

	ensureRootsLoaded({ commit }, { type, doNotLoadChildren }) {
		return Common.ensureRootsLoaded({ commit }, { type, doNotLoadChildren });
	},

	loadCounts({ commit, state, rootGetters }) {
		return Common.loadCounts({ commit, state, rootGetters }, 'category');
	},
};

const mutations = {
	setEcId(state, { id }) {
		state.entityclassId = id;
	},

	setEntry(state, entryToSet) {
		Common.setEntry(state, entryToSet);
	},

	setEntries(state, entriesToSet) {
		Common.setEntries(state, entriesToSet);
	},

	setList(state, categories) {
		state.entries = categories;

		for (let entity of state.entries) {
			if (!entity.children) {
				entity.children = [];
			}

			entity.children = getters.byParent(state)(entity.id);
		}
	},

	setCounts(state, counts) {
		state.counts = counts;
	},

	resetCounts(state) {
		state.counts = {};
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
