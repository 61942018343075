<template>
	<b-button-group v-if="showLogin" class="float-right">
		<b-button disabled v-show="hasPdfQueued">
			<b-spinner small />
			Ihr PDF wird erstellt...
		</b-button>
		<b-button
			:to="{ name: 'PublicSearch' }"
			:disabled="route === 'PublicSearch' || !hasTosAccepted"
		>
			{{ $t('menu.search') }}
		</b-button>
		<paid-login />
	</b-button-group>
</template>

<script>
import PaidLogin from '@/components/PaidLogin';

export default {
	name: 'MaintenanceLayout',

	components: {
		PaidLogin,
	},

	computed: {
		hasTosAccepted() {
			return this.$store.getters['auth/user/hasTosAccepted'];
		},
		route() {
			return this.$route.name;
		},
		hasPdfQueued() {
			return this.$store.getters['pdf/hasJobQueued'];
		},
		showLogin() {
			return this.$store.getters['search/usePaidUserUi'];
		},
	},

	mounted() {
		if (this.hasPdfQueued) {
			//will check for existing pdf tickets that got persisted
			this.$store.dispatch('pdf/waitForTicket');
		}
	},
};
</script>
