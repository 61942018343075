export function getBackendSearchConfiguration(
	searchConfiguration,
	{ locale, fallbackLocale, isEuMode, standardFilter, mandatoryCategories, institutionsFilter },
) {
	let backendConfig = {
		list: searchConfiguration.list,
		locale,
		fallbackLocale,
		isEuMode,
	};
	if (searchConfiguration.productId) {
		backendConfig.productId = searchConfiguration.productId;
		if (searchConfiguration.searchHistoryDate) {
			backendConfig.historyDate = searchConfiguration.searchHistoryDate;
		}
	} else {
		if (searchConfiguration.productTitle)
			backendConfig.productTitle = searchConfiguration.productTitle.substring(0, 100);
		if (searchConfiguration.distributor)
			backendConfig.distributor = searchConfiguration.distributor.substring(0, 100);
		if (searchConfiguration.category && searchConfiguration.category.length) {
			backendConfig.category = JSON.parse(JSON.stringify(searchConfiguration.category));
		}
		if (mandatoryCategories && mandatoryCategories.length) {
			if (!Array.isArray(backendConfig.category)) backendConfig.category = [];
			for (const category of mandatoryCategories) {
				if (!backendConfig.category.includes(category)) {
					backendConfig.category.push(category);
				}
			}
		}
		if (searchConfiguration.standard && searchConfiguration.standard.length)
			backendConfig.standard =
				typeof searchConfiguration.standard === 'string'
					? [searchConfiguration.standard]
					: searchConfiguration.standard;
		if (standardFilter && standardFilter.length)
			backendConfig.mandatoryStandards = standardFilter;
		if (searchConfiguration.searchHistory && searchConfiguration.searchHistoryDate) {
			backendConfig.historyDate = searchConfiguration.searchHistoryDate;
		} else {
			if (searchConfiguration.substance)
				backendConfig.substance = searchConfiguration.substance.value
					? searchConfiguration.substance.value
					: searchConfiguration.substance;
			if (searchConfiguration.animalKinds && searchConfiguration.animalKinds.length)
				backendConfig.animalKinds = searchConfiguration.animalKinds;
			if (searchConfiguration.psmCulture && searchConfiguration.psmCulture.length)
				backendConfig.psmCulture = searchConfiguration.psmCulture;
			if (searchConfiguration.hobbygarden && searchConfiguration.hobbygarden.length)
				backendConfig.hobbygarden = searchConfiguration.hobbygarden[0];
			if (searchConfiguration.component && searchConfiguration.component.length) {
				backendConfig.component = searchConfiguration.component;
			}
			if (searchConfiguration.cultureDetailDescription) {
				if (!backendConfig.forceNationalListFallback)
					backendConfig.forceNationalListFallback = [];
				backendConfig.forceNationalListFallback.push('cultureDetailDescription');
				backendConfig.cultureDetailDescription =
					searchConfiguration.cultureDetailDescription;
			}
			if (searchConfiguration.pestOrganism) {
				if (!backendConfig.forceNationalListFallback)
					backendConfig.forceNationalListFallback = [];
				backendConfig.forceNationalListFallback.push('pestOrganism');
				backendConfig.pestOrganism = searchConfiguration.pestOrganism;
			}
			if (searchConfiguration.applicationEdited)
				backendConfig.applicationEdited = searchConfiguration.applicationEdited;
			if (searchConfiguration.applicationParasites)
				backendConfig.applicationParasites = searchConfiguration.applicationParasites;
		}
	}
	if (Array.isArray(institutionsFilter) && institutionsFilter.length) {
		backendConfig.institutions = institutionsFilter;
	}
	return backendConfig;
}
