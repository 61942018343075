import Vue from 'vue';
import Vuelidate from 'vuelidate';
import VueI18n from 'vue-i18n';
import Autocomplete from 'v-autocomplete';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './plugins/filters.js';
import '@/assets/scss/custom.scss';
import { languages } from '@/assets/config/translations/index.js';
import './util/init.js';
import { parseEnv } from './util/env';

const languageStrings = Object.assign(languages);

Vue.config.productionTip = false;

Vue.use(Vuelidate);
Vue.use(VueI18n);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

Vue.component('v-autocomplete', Autocomplete);

const mountElement = parseEnv(store);

export const i18n = new VueI18n({
	locale: store.state.search.selectedLocale,
	fallbackLocale: 'en_GB',
	messages: languageStrings,
});

new Vue({
	router,
	store,
	i18n,
	render: (h) => h(App),
}).$mount(mountElement);
