import { getEntityclassByExternalId } from '@/repository/system.js';
import { search, aggregateSearch } from '@/repository/system';
import { getBackendSearchConfiguration } from '@/util/search';
import LRU from 'lru-cache';

const state = {
	fields: [],
	allLoaded: false,
	filteredSubstanceOptions: [],
	filteredPsmCultureOptions: [],
	filteredAnimalKindsOptions: [],
};

const aggregateCache = new LRU({
	maxAge: 1000 * 60 * 10,
	max: 100,
});

const CLASS_ID = 'fields';
export const WKST_FIELD = 'wkst-wkst';
export const TA_FIELD = 'ta-s1';
export const PSM_FIELD = 'psm-s1';

const getters = {
	fields: (state) => {
		return state.fields;
	},
	optionsByFieldPath: (state) => (fieldPath) => {
		const field = state.fields.find((f) => f.data.path === fieldPath);
		if (!field || !field.data.options) {
			return [];
		}
		return field.data.options;
	},
	getOptionGroup: (state) => (fieldPath) => {
		const field = state.fields.find((f) => f.data.path === fieldPath);
		if (!field || !field.data.optionGroups) {
			return [];
		}
		return field.data.optionGroups;
	},
	byId: (state) => (id) => {
		return state.fields.find((f) => f.id === id);
	},
	getFilteredSubstanceOptions: (state) => {
		return state.filteredSubstanceOptions;
	},
	getFilteredPsmCultureOptions: (state) => {
		return state.filteredPsmCultureOptions;
	},
	getFilteredAnimalKindsOptions: (state) => {
		return state.filteredAnimalKindsOptions;
	},
	getAllOptionIds: (state) => {
		return state.fields.flatMap((e) => e.data.options).map((e) => e.id);
	},
};

const actions = {
	async loadAll({ commit, state }) {
		if (state.allLoaded) return;
		let ec = await getEntityclassByExternalId(CLASS_ID);
		const result = await search(ec.id);
		commit('addAllFields', result);
	},

	async loadWkst({ commit, state }) {
		if (state.fields.find((e) => e.data.path === WKST_FIELD)) return;
		const attributeFilter = { $or: [] };
		attributeFilter.$or.push({
			field: '/data/path',
			comparison: 'eq',
			value: WKST_FIELD,
		});
		let ec = await getEntityclassByExternalId(CLASS_ID);
		const result = await search(ec.id, attributeFilter);
		if (result.length === 0) throw new Error(`field not found: ${WKST_FIELD}`);
		commit('addSingleField', result[0]);
	},

	async loadTaS1Field({ commit, state }) {
		if (state.fields.find((e) => e.data.path === TA_FIELD)) return;
		const attributeFilter = { $or: [] };
		attributeFilter.$or.push({
			field: '/data/path',
			comparison: 'eq',
			value: TA_FIELD,
		});
		let ec = await getEntityclassByExternalId(CLASS_ID);
		const result = await search(ec.id, attributeFilter);
		if (result.length === 0) throw new Error(`field not found: ${TA_FIELD}`);
		commit('addSingleField', result[0]);
	},

	async loadPSMField({ commit, state }) {
		if (state.fields.find((e) => e.data.path === PSM_FIELD)) return;
		const attributeFilter = { $or: [] };
		attributeFilter.$or.push({
			field: '/data/path',
			comparison: 'eq',
			value: PSM_FIELD,
		});
		let ec = await getEntityclassByExternalId(CLASS_ID);
		const result = await search(ec.id, attributeFilter);
		if (result.length === 0) throw new Error(`field not found: ${PSM_FIELD}`);
		commit('addSingleField', result[0]);
	},

	async loadFilteredSubstanceOptions({ commit, rootGetters }, searchConfiguration) {
		const params = {
			locale: rootGetters['search/getLocale'],
			fallbackLocale: rootGetters['search/getFallbackLocale'],
			isEuMode: rootGetters['search/getEuMode'],
		};
		let copy = JSON.parse(JSON.stringify(searchConfiguration));
		if ('substance' in copy) delete copy.substance;
		let key = JSON.stringify(copy);
		let existing = aggregateCache.get(key);
		if (existing) {
			commit('setFilteredSubstanceOptions', existing);
			return;
		}
		let options = await aggregateSearch(
			getBackendSearchConfiguration(searchConfiguration, params),
			{ aggregateType: 'substance' },
		);
		if (
			options &&
			options.aggregations &&
			options.aggregations.aggregate &&
			options.aggregations.aggregate.aggregate.buckets
		) {
			options = options.aggregations.aggregate.aggregate.buckets;
		} else {
			options = [];
		}
		aggregateCache.set(key, options);
		commit('setFilteredSubstanceOptions', options);
	},

	async loadFilteredPsmCultureOptions({ commit, rootGetters }, searchConfiguration) {
		const params = {
			locale: rootGetters['search/getLocale'],
			fallbackLocale: rootGetters['search/getFallbackLocale'],
			isEuMode: rootGetters['search/getEuMode'],
		};
		let copy = JSON.parse(JSON.stringify(searchConfiguration));
		if ('psmCulture' in copy) delete copy.psmCulture;
		let key = JSON.stringify(copy);
		let existing = aggregateCache.get(key);
		if (existing) {
			commit('setFilteredPsmCultureOptions', existing);
			return;
		}
		let options = await aggregateSearch(
			getBackendSearchConfiguration(searchConfiguration, params),
			{ aggregateType: 'psmCulture' },
		);
		if (
			options &&
			options.aggregations &&
			options.aggregations.aggregate &&
			options.aggregations.aggregate.aggregate.buckets
		) {
			options = options.aggregations.aggregate.aggregate.buckets;
		} else {
			options = [];
		}
		aggregateCache.set(key, options);
		commit('setFilteredPsmCultureOptions', options);
	},

	async loadFilteredAnimalKindsOptions({ commit, rootGetters }, searchConfiguration) {
		const params = {
			locale: rootGetters['search/getLocale'],
			fallbackLocale: rootGetters['search/getFallbackLocale'],
			isEuMode: rootGetters['search/getEuMode'],
		};
		let copy = JSON.parse(JSON.stringify(searchConfiguration));
		if ('animalKinds' in copy) delete copy.animalKinds;
		let key = JSON.stringify(copy);
		let existing = aggregateCache.get(key);
		if (existing) {
			commit('setFilteredAnimalKindsOptions', existing);
			return;
		}
		let options = await aggregateSearch(
			getBackendSearchConfiguration(searchConfiguration, params),
			{ aggregateType: 'animalKinds' },
		);
		if (
			options &&
			options.aggregations &&
			options.aggregations.aggregate &&
			options.aggregations.aggregate.aggregate.buckets
		) {
			options = options.aggregations.aggregate.aggregate.buckets;
		} else {
			options = [];
		}
		aggregateCache.set(key, options);
		commit('setFilteredAnimalKindsOptions', options);
	},
};

const mutations = {
	addAllFields(state, result) {
		state.fields = result;
		state.allLoaded = true;
	},

	addSingleField(state, field) {
		state.fields.push(field);
	},

	setFilteredSubstanceOptions(state, options) {
		state.filteredSubstanceOptions = options;
	},

	setFilteredPsmCultureOptions(state, options) {
		state.filteredPsmCultureOptions = options;
	},

	setFilteredAnimalKindsOptions(state, options) {
		state.filteredAnimalKindsOptions = options;
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
