import * as Common from './tree-common';

const state = {
	components: [],
	counts: {},
	entityclassId: '',
};

const getters = {
	byParent: (state) => (id) => {
		return state.components.filter((c) => c.data.parent === id && !c.data.disabled);
	},
	byId: (state) => (id) => {
		return state.components.find((c) => c.id === id);
	},
	all: (state) => {
		return state.components;
	},
	getLevel: (state, getters) => (id) => {
		return Common.getLevel(getters.roots, 0, id);
	},
	pathToRoot: (state) => (ids) => {
		return Common.pathToRoot(getters.byId(state), ids);
	},
	rootByMainCategory: (state) => (mainCategoryKey) => {
		return getters.roots(state).find((f) => f.data.mainCategoryMapping === mainCategoryKey);
	},
	countById: (state) => (id) => {
		return state.counts[id] || 1;
	},
	numberString: (state, getters) => (id) => {
		let numberString = '';
		if (!id) {
			return '';
		}
		let result = getters.pathToRoot([id]);
		if (!result) return '';
		result = result.reverse();
		let index = 0;
		while (result[index]) {
			let component = getters.byId(result[index]);
			numberString = numberString
				? `${numberString}-${component.data.order}`
				: component.data.order;
			index++;
		}
		return numberString;
	},
	roots: (state) => {
		return state.components
			.filter((r) => !r.data.parent)
			.sort((a, b) => {
				return a.data.order < b.data.order ? -1 : 1;
			});
	},
};

const actions = {
	loadEntitesById({ commit }, ids) {
		return Common.loadEntitesById({ commit }, ids);
	},

	loadParentWithChildren({ commit, state }, { children, type }) {
		return Common.loadParentWithChildren({ commit, state }, { children, type });
	},

	ensureRootsLoaded({ commit }, { type }) {
		return Common.ensureRootsLoaded({ commit }, { type });
	},
};

const mutations = {
	setEcId(state, { id }) {
		state.entityclassId = id;
	},

	setEntry(state, entryToSet) {
		Common.setEntry(state, entryToSet);
	},

	setEntries(state, entriesToSet) {
		Common.setEntries(state, entriesToSet);
	},

	setList(state, components) {
		components = components.sort((a, b) => {
			return a.data.order < b.data.order ? -1 : 1;
		});
		state.components = components;
		for (let entity of state.components) {
			if (!entity.children) {
				entity.children = [];
			}

			entity.children = getters.byParent(state)(entity.id);
		}
	},

	setCounts(state, counts) {
		state.counts = counts;
	},

	resetCounts(state) {
		state.counts = {};
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
