import {
	getEntitiesById,
	loadParentsWithChildren,
	loadRoots,
	aggregateSearch,
} from '@/repository/system.js';
import { fieldToLocale } from '@/util/locale';
import { getBackendSearchConfiguration } from '@/util/search';

export function pathToRoot(idGetter, ids) {
	let entries = ids.slice(0);
	for (const id of ids) {
		const category = idGetter(id);
		if (category.data.parent) {
			let parent = idGetter(category.data.parent);
			while (parent) {
				if (!entries.includes(parent.id)) {
					entries.push(parent.id);
				}

				parent = idGetter(parent.data.parent);
			}
		}
	}
	return entries;
}

export function getLevel(roots, depth, id) {
	let actualDepth = 0;

	let _traverseElements = function (elements, depth, id) {
		for (const el of elements) {
			if (el.id === id) {
				actualDepth = depth;
			}

			if (el.children && el.children.length > 0) {
				_traverseElements(el.children, depth + 1, id, actualDepth);
			}
		}
	};

	_traverseElements(roots, depth, id);
	return actualDepth;
}

export function getEntryByParent(entries, id) {
	return entries.filter((r) => r.data.parent === id);
}

export async function loadEntitesById({ commit }, ids) {
	const categories = await getEntitiesById(ids);
	if (categories && categories.length) {
		let entries = categories;
		for (const category of categories) {
			if (category.children && category.children.length)
				entries = entries.concat(category.children);
		}
		commit('setEntries', entries);
	}
}

export async function loadParentWithChildren({ commit, state }, { children, type }) {
	if (!children || !children.length) return [];
	let parents = await loadParentsWithChildren({
		type,
		parentIds: children.map((child) => child.id),
	});
	let entries = parents;
	for (const parent of parents) {
		if (parent.children && parent.children.length) entries = entries.concat(parent.children);
	}
	commit('setEntries', entries);
	parents =
		getEntrylabel(state) === 'components' ? sortComponents(parents) : sortCategories(parents);
	return parents;
}

export async function ensureRootsLoaded({ commit }, { type, doNotLoadChildren }) {
	const roots = await loadRoots({ type, doNotLoadChildren });
	if (!roots.length) return;
	let entries = roots;
	for (const category of roots) {
		if (category.children && category.children.length)
			entries = entries.concat(category.children);
	}
	commit('setEntries', entries);
}

export async function loadCounts({ commit, state, rootGetters }, aggregateType) {
	if (Object.keys(state.counts).length > 0) return;
	let searchConfig = rootGetters['searchUi/noCategory'];
	let countResult = await aggregateSearch(
		getBackendSearchConfiguration(searchConfig, rootGetters['search/getBackendParameters']()),
		{ aggregateType },
	);
	let counts = Object.fromEntries(
		countResult.aggregations.aggregate.buckets.map((e) => [e.key, e.doc_count]),
	);
	commit('setCounts', counts);
}

export function setEntry(state, entryToSet) {
	let entryLabel = getEntrylabel(state);
	const entryIndex = state[entryLabel].findIndex((entry) => entry.id === entryToSet.id);
	if (entryIndex === -1) {
		state[entryLabel].push(entryToSet);
	} else {
		state[entryLabel].splice(entryIndex, 1, entryToSet);
	}
}

function getEntrylabel(state) {
	return state.components ? 'components' : 'entries';
}

function sortComponents(items) {
	return items.sort((a, b) => {
		let va = fieldToLocale(a.data.title);
		let vb = fieldToLocale(b.data.title);
		return va.localeCompare(vb);
	});
}

function sortCategories(items) {
	return items.sort((a, b) => {
		return a.data.order < b.data.order ? -1 : 1;
	});
}

export function setEntries(state, entriesToSet) {
	for (const entryToSet of entriesToSet) {
		setEntry(state, entryToSet);
	}
	sortEntries(state);
}

function sortEntries(state) {
	if (getEntrylabel(state) === 'components') {
		state.components = sortComponents(state.components);
	} else if (getEntrylabel(state) === 'entries') {
		state.entries = sortCategories(state.entries);
	}
}
