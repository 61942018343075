<template>
	<b-form-group>
		<multi-select
			:value="workdata"
			@input="onChange"
			:options="options"
			:multiple="multiple"
			:close-on-select="closeOnSelect"
			:track-by="idKey"
			:label="labelKey"
			:placeholder="placeholder"
			:select-label="selectLabel"
			:select-group-label="selectGroupLabel"
			:deselect-label="deselectLabel"
			:deselect-group-label="deselectGroupLabel"
			:searchable="searchable"
			:group-values="groupValues"
			:group-label="groupLabel"
			:group-select="groupSelect"
			:should-be-grouped="shouldBeGrouped"
			@open="onOpen"
		/>
	</b-form-group>
</template>

<script>
import MultiSelect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.min.css';
export default {
	name: 'MultiSelectInt',

	components: {
		MultiSelect,
	},

	props: {
		value: {
			required: true,
		},
		options: {
			type: Array,
			required: true,
		},
		idKey: {
			type: String,
			required: true,
		},
		labelKey: {
			type: String,
			required: true,
		},
		searchable: {
			type: Boolean,
		},
		multiple: {
			type: Boolean,
		},
		placeholder: {
			type: String,
		},
		selectLabel: {
			type: String,
		},
		deselectLabel: {
			type: String,
		},
		closeOnSelect: {
			type: Boolean,
		},
		groupValues: {
			type: String,
		},
		groupLabel: {
			type: String,
		},
		selectGroupLabel: {
			type: String,
		},
		deselectGroupLabel: {
			type: String,
		},
		groupSelect: {
			type: Boolean,
		},
		shouldBeGrouped: {
			type: Boolean,
		},
	},

	data() {
		let data = {
			workdata: this.multiple ? [] : null,
		};
		return data;
	},

	watch: {
		value() {
			this.init();
		},
	},

	mounted() {
		this.init();
	},

	methods: {
		init() {
			const flatOptions = this.shouldBeGrouped
				? this.options.flatMap((e) => e[this.groupValues])
				: this.options;
			if (this.multiple) {
				this.workdata = flatOptions.filter(
					(o) => this.value && this.value.includes(o[this.idKey]),
				);
			} else {
				let option = flatOptions.find(
					(o) => this.value && this.value[this.idKey] === o[this.idKey],
				);
				this.workdata = option ? option : null;
			}
		},
		onChange(newVal) {
			let newIds = [];
			if (this.multiple) {
				newIds = newVal.map((opt) => opt[this.idKey]);
			} else {
				newIds = newVal ? newVal : null;
			}
			this.$emit('input', newIds);
		},
		onOpen() {
			this.$emit('open');
		},
	},
};
</script>
