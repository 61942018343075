import Languages from '@/assets/config/languages.json';

const state = {
	languages: Languages,
};

const getters = {
	getLanguagesForSelect: (state, getters, rootState) => {
		let restrictions = rootState.search.restrictLanguages.length
			? rootState.search.restrictLanguages
			: null;
		return state.languages
			.filter((l) => !(restrictions && !restrictions.includes(l.key)))
			.map((code) => {
				return { value: code.key, text: code.title };
			});
	},
};

export default {
	namespaced: true,
	state,
	getters,
};
