import { search, getEntityclassByExternalId } from '@/repository/system.js';

const state = {
	biostandards: [],
	entityClassId: null,
};

const getters = {
	byId: (state) => (id) => {
		return state.biostandards.find((biostandard) => biostandard.id === id);
	},
	all: (state) => {
		return state.biostandards;
	},
	withPublicName: (state) => {
		return state.biostandards.filter((f) => !!f.data.publicName);
	},
	hasPublicNameWithLocale: (state, getters, _, rootGetters) => (biostandardId) => {
		let locale = rootGetters['search/getLocale'];
		let biostandard = getters.byId(biostandardId);
		return (
			biostandard &&
			biostandard.data.publicName &&
			biostandard.data.publicName[locale] &&
			biostandard.data.publicName[locale].trim()
		);
	},
	isStandardActiveInList: (state, getters) => (biostandardId, listId) => {
		let biostandard = getters.byId(biostandardId);
		if (!biostandard) return false;
		if (Array.isArray(biostandard.data.activeInLists)) {
			return biostandard.data.activeInLists.includes(listId);
		} else {
			return getters.hasPublicNameWithLocale(biostandardId);
		}
	},
	allActiveInList: (state, getters) => (listId) => {
		return state.biostandards.filter((b) => getters.isStandardActiveInList(b.id, listId));
	},
	toRoot: (state) => (id) => {
		let elements = [];
		let traverseToRoot = function (elements, standards, current) {
			const parent = standards.find((f) => f.id === current.data.dependency);
			if (!parent) {
				return;
			}

			elements.push(parent);
			traverseToRoot(elements, standards, parent);
		};

		const obj = getters.byId(state)(id);
		if (!obj) {
			return null;
		}

		traverseToRoot(elements, state.biostandards, obj);

		return elements;
	},
};

const actions = {
	async loadAll({ commit, state }, { ifEmpty }) {
		if (ifEmpty && state.biostandards.length > 0) return;

		if (!state.entityClassId) {
			let entityClass = await getEntityclassByExternalId('biostandard');
			commit('setEntityClassId', entityClass);
		}

		let list = await search(state.entityClassId);
		if (list && Array.isArray(list)) {
			list = list.sort((a, b) => {
				return a.data.publicOrder > b.data.publicOrder ? 1 : -1;
			});
		}
		commit('setEntries', list);
	},
};

const mutations = {
	setEntityClassId(state, { id }) {
		state.entityClassId = id;
	},

	setEntries(state, entries) {
		state.biostandards = entries;
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
