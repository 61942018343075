<template>
	<b-card class="text-left">
		<b-card-text v-if="initialized">
			<b-alert v-if="error" variant="danger" show>
				{{ $t('searchForm.validation') }}
			</b-alert>

			<b-form-group
				:label="$t('searchForm.language')"
				label-cols="3"
				label-class="font-weight-bold"
				v-show="localeOptions.length > 1"
			>
				<b-form-select :options="localeOptions" v-model="selectedLocale" />
			</b-form-group>

			<b-form-group
				:label="$t('searchForm.list')"
				label-cols="3"
				label-class="font-weight-bold"
				v-if="showListFilter"
			>
				<b-form-select
					:options="listOptions"
					v-model="searchConfiguration.list"
					@change="updateSearchList()"
				/>
			</b-form-group>

			<b-form-group
				:label="$t('searchForm.product')"
				label-cols="3"
				label-class="font-weight-bold"
			>
				<v-autocomplete
					:items="productTitleData"
					v-model="productTitleSelected"
					@update-items="getProductTitles"
					@change="setProductTitle"
					@keyup.enter.native="executeSearch"
					:wait="500"
					:min-len="3"
					:auto-select-one-item="false"
				/>
			</b-form-group>

			<b-form-group
				:label="$t('searchForm.distributor')"
				label-cols="3"
				label-class="font-weight-bold"
			>
				<v-autocomplete
					:items="distributorData"
					v-model="distributorSelected"
					@update-items="getDistributors"
					@change="setDistributorSelected"
					@keyup.enter.native="executeSearch"
					:wait="500"
					:min-len="3"
					:auto-select-one-item="false"
					:get-label="getDistbutorLabelFromObject"
					:component-item="template"
				/>
			</b-form-group>

			<b-form-group
				:label="$t('searchForm.standard')"
				label-cols="3"
				label-class="font-weight-bold"
				v-if="showStandardFilter"
			>
				<b-form-select :options="standardOptions" v-model="searchConfiguration.standard" />
			</b-form-group>

			<b-form-group
				:label="$t('searchForm.category')"
				label-cols="3"
				label-class="font-weight-bold"
				v-if="showCategoryArea"
			>
				<tree-select
					:prefix="'category'"
					v-model="searchConfiguration.category"
					:search-conf="searchConfiguration"
					:root-filter="categoryFilter"
					:multi-select="true"
					:root-single-select="false"
					@input="onChangeCategory"
				/>
			</b-form-group>

			<b-form-group
				:label="$t('searchForm.substance')"
				label-cols="3"
				label-class="font-weight-bold"
				v-if="showSubstanceArea"
			>
				<multi-select
					:multiple="false"
					:options="substanceOptions"
					:close-on-select="true"
					id-key="value"
					label-key="text"
					v-model="searchConfiguration.substance"
					@open="handleSubstanceOpen"
				/>
				<b-link
					@click="searchConfiguration.substance = null"
					v-if="searchConfiguration.substance"
				>
					{{ $t('tree.reset') }}
				</b-link>
			</b-form-group>

			<b-form-group
				:label="$t('searchForm.biologicGardening')"
				label-cols="3"
				label-class="font-weight-bold"
				v-if="showHobbyGardenArea"
			>
				<tree-select
					:prefix="'hobbygarden'"
					v-model="searchConfiguration.hobbygarden"
					:use-model="true"
				/>
			</b-form-group>

			<b-form-group
				:label="$t('searchForm.component')"
				label-cols="3"
				label-class="font-weight-bold"
				v-if="showComponentArea"
			>
				<tree-select
					:prefix="'component'"
					v-model="searchConfiguration.component"
					:root-expanded="true"
					:root-filter="componentRootFilter"
					:use-model="true"
				/>
			</b-form-group>

			<b-form-group
				:label="$t('searchForm.animalKinds')"
				label-cols="3"
				label-class="font-weight-bold"
				v-if="showAnimalKindsArea"
			>
				<multi-select
					:placeholder="$t('multiSelect.selectOption')"
					:select-label="$t('multiSelect.selectLabel')"
					:select-group-label="$t('multiSelect.selectGroupLabel')"
					:deselect-label="$t('multiSelect.deselectLabel')"
					:deselect-group-label="$t('multiSelect.deselectGroupLabel')"
					:multiple="true"
					v-model="searchConfiguration.animalKinds"
					:options="animalKindsOptions"
					group-values="animalKinds"
					group-label="animalType"
					:group-select="false"
					:should-be-grouped="true"
					track-by="id"
					label-key="name"
					id-key="id"
					:searchable="true"
					@open="handleAnimalKindsOpen"
				/>
			</b-form-group>

			<b-form-group
				:label="$t('searchForm.psmCulture')"
				label-cols="3"
				label-class="font-weight-bold"
				v-if="showPsmCultureArea"
			>
				<multi-select
					:multiple="true"
					:options="PsmCultureOptions"
					:placeholder="$t('multiSelect.selectOption')"
					:select-label="$t('multiSelect.selectLabel')"
					:select-group-label="$t('multiSelect.selectGroupLabel')"
					:deselect-label="$t('multiSelect.deselectLabel')"
					:deselect-group-label="$t('multiSelect.deselectGroupLabel')"
					id-key="value"
					label-key="text"
					v-model="searchConfiguration.psmCulture"
					@open="handlePsmOpen"
				/>
				<b-link
					@click="searchConfiguration.psmCulture = null"
					v-if="searchConfiguration.psmCulture"
				>
					{{ $t('tree.reset') }}
				</b-link>
			</b-form-group>

			<b-form-group
				:label="$t('searchForm.cultureDetailDescription')"
				label-cols="3"
				label-class="font-weight-bold"
				v-if="showCultureDetailDescription"
			>
				<b-form-input v-model="searchConfiguration.cultureDetailDescription" />
			</b-form-group>

			<b-form-group
				:label="$t('searchForm.pestOrganism')"
				label-cols="3"
				label-class="font-weight-bold"
				v-if="showPestOrganism"
			>
				<b-form-input v-model="searchConfiguration.pestOrganism" />
			</b-form-group>

			<b-form-group
				:label="$t('searchForm.applicationEdited')"
				label-cols="3"
				label-class="font-weight-bold"
				v-if="showApplicationEdited"
			>
				<b-form-input v-model="searchConfiguration.applicationEdited" />
			</b-form-group>

			<b-form-group
				:label="$t('searchForm.applicationParasites')"
				label-cols="3"
				label-class="font-weight-bold"
				v-if="showApplicationParasites"
			>
				<b-form-input v-model="searchConfiguration.applicationParasites" />
			</b-form-group>

			<b-row cols="4">
				<b-col id="searchFooterArchiveWrap">
					<b-checkbox
						v-model="searchConfiguration.searchHistory"
						@change="onClickSearchHistory"
						style="display: inline-block; margin-right: 0.5em"
					>
						{{ $t('searchForm.searchHistory') }}
					</b-checkbox>
					<b-icon-question-circle
						scale="1.1"
						id="spot-market-popover"
						variant="outline-secondary"
					/>
					<b-popover
						target="spot-market-popover"
						triggers="click blur hover"
						placement="topleft"
						container="#searchFooterArchiveWrap"
					>
						<template #title>
							{{ $t('searchForm.searchHistory') }}
						</template>
						<p>
							{{ $t('searchForm.searchHistoryExplanation') }}
						</p>
					</b-popover>
				</b-col>
				<b-col v-if="searchConfiguration.searchHistory">
					<b-form-datepicker
						v-model="searchConfiguration.searchHistoryDate"
						:max="maxHistoryDate"
						:min="minHistoryDate"
						:label="$t('searchForm.searchHistory')"
					/>
				</b-col> </b-row
			><br />

			<b-input-group-append>
				<b-row>
					<b-col sm="7">
						<b-button @click.prevent="resetSearch" :disabled="disabled" class="mr-2">
							{{ $t('searchForm.resetSearch') }}
						</b-button>
					</b-col>
					<b-col sm="5">
						<b-button
							@click.prevent="executeSearch()"
							:disabled="disabled"
							variant="primary"
							class="mr-2"
						>
							{{ $t('searchForm.executeSearch') }}
						</b-button>
					</b-col>
				</b-row>

				<b-form-group class="w-100 text-right">
					<b-card-text class="w-100 text-right font-weight-bold" im img-top>
						<b-link @click="showAgbModal">
							{{ $t('searchForm.termsOfService.link') }} </b-link
						>&nbsp;&nbsp;&nbsp; Powered by
						<img height="60" class="p-3" alt="Logo" src="@/assets/images/logo.png" />
					</b-card-text>
				</b-form-group>
			</b-input-group-append>
		</b-card-text>
		<b-card-text v-else>
			<p>Loading...</p>
		</b-card-text>

		<b-modal
			ref="agbModal"
			scrollable
			size="xl"
			ok-only
			:title="$t('searchForm.termsOfService.title')"
			static
			lazy
		>
			<b-card-text id="BmlTos">
				{{ $t('searchForm.termsOfService.text') }}
			</b-card-text>
		</b-modal>
	</b-card>
</template>

<script>
import { SearchOptionsProvider } from '@/services/SearchOptionsProvider';
import { required } from 'vuelidate/lib/validators';
import TreeSelect from './SearchControls/TreeSelection/TreeSelect';
import debounce from 'underscore/modules/debounce';
import { fieldToLocale } from '@/util/locale';
import { pathToRoot } from '@/store/tree-common';
import { getBackendSearchConfiguration } from '@/util/search';
import { autocomplete } from '@/repository/bml';
import { aggregateSearch } from '@/repository/system';
import itemTemplate from '@/assets/templates/autocomplete-template';
import MultiSelect from '@/components/field/MultiSelect';

export default {
	name: 'PublicSearchForm',

	components: {
		TreeSelect,
		MultiSelect,
	},

	props: {
		disabled: {
			type: Boolean,
			default: false,
		},
	},

	data() {
		return {
			searchConfiguration: {
				list: '',
				productTitle: '',
				productTitleData: [],
				distributor: '',
				distributorData: [],
				standard: '',
				category: [],
				substance: null,
				hobbygarden: [],
				component: [],
				cultureDetailDescription: '',
				pestOrganism: '',
				applicationEdited: '',
				applicationParasites: '',
				searchHistory: false,
				searchHistoryDate: '',
				animalKinds: [],
				psmCulture: [],
			},
			initialized: false,
			error: false,
			productTitleData: [],
			productTitleSelected: null,
			distributorData: [],
			distributorSelected: { name: '' },
			template: itemTemplate,
		};
	},

	computed: {
		selectedLocale: {
			get() {
				return this.$store.getters['search/getLocale'];
			},
			set(v) {
				if (!v) {
					return;
				}

				this.$store.commit('search/setLocale', v);
				this.$i18n.locale = v;
			},
		},

		localeOptions() {
			return SearchOptionsProvider.getLocaleOptions();
		},

		listOptions() {
			let allLists;
			if (!this.isEuMode) {
				allLists = SearchOptionsProvider.getListOptions();
			} else {
				allLists = SearchOptionsProvider.getListOptionsUnfiltered();
				// filter out eu list as it gets pre-selected.
				allLists = allLists.filter((f) => f.data.targetAudience !== 'hobby');
			}
			return allLists.map((obj) => {
				return {
					value: obj.id,
					text: fieldToLocale(obj.data.title),
				};
			});
		},

		maxHistoryDate() {
			let now = new Date();
			now.setDate(now.getDate() - 1);
			return now.toJSON().substring(0, 10);
		},
		minHistoryDate() {
			let now = new Date('2022-06-10'); //1 day after swiss. same day makes problems with time
			return now.toJSON().substring(0, 10);
		},

		showListFilter() {
			if (this.isEuMode) {
				return true;
			}

			return this.listOptions.length > 1;
		},

		showStandardFilter() {
			return this.$store.state.search.showStandardFilter;
		},

		showCultureDetailDescription() {
			return (
				this.isFieldDisplayed('cultureDetailDescription') &&
				this.isAnyCategorySelected('plant-protection-agents')
			);
		},

		showPestOrganism() {
			return (
				this.isFieldDisplayed('pestOrganism') &&
				this.isAnyCategorySelected('plant-protection-agents')
			);
		},

		showApplicationEdited() {
			return (
				this.isFieldDisplayed('applicationEdited') &&
				this.isAnyCategorySelected('cleaning-disinfection-agents')
			);
		},

		showApplicationParasites() {
			return (
				this.isFieldDisplayed('applicationParasites') &&
				this.isAnyCategorySelected('antiparasitica')
			);
		},

		showActiveAgent() {
			return this.isFieldDisplayed('activeAgent');
		},

		showComponents() {
			return this.isFieldDisplayed('showComponents');
		},

		selectedList() {
			return this.listOptions.find((f) => f.value === this.searchConfiguration.list);
		},

		standardOptions() {
			let standards = SearchOptionsProvider.getStandardOptions();

			let listId;
			if (this.isEuMode) {
				listId = this.$store.getters['list/baseListId'];
			} else if (this.selectedList) {
				listId = this.selectedList.value;
			}

			if (!listId) {
				return [{ value: '', text: '-' }];
			}

			let restrictStandards = this.$store.getters['search/getRestrictStandards'](listId);
			const listStandards = this.$store.getters['list/standardsByListId'](listId);

			if (listStandards && listStandards.length > 0) {
				standards = standards.filter((f) => listStandards.includes(f.id));
			}
			standards = standards.filter((f) =>
				this.$store.getters['biostandard/isStandardActiveInList'](f.id, listId),
			);

			if (restrictStandards && restrictStandards.length) {
				standards = standards.filter((standard) => restrictStandards.includes(standard.id));
			}

			const options = SearchOptionsProvider.createSelectListFromArray(
				standards,
				true,
				'publicName',
			);
			options.unshift({
				value: '',
				text: '-',
			});
			return options;
		},

		substanceOptions() {
			if (!this.showSubstanceArea) return [];
			return SearchOptionsProvider.getFilteredSubstanceOptions(
				this.searchConfiguration.substance,
			);
		},

		animalKindsOptions() {
			if (!this.showAnimalKindsArea) return [];
			return SearchOptionsProvider.getFilteredAnimalKindsOptions(
				this.searchConfiguration.animalKinds,
			);
		},

		PsmCultureOptions() {
			if (!this.showPsmCultureArea) return [];
			return SearchOptionsProvider.getFilteredPsmCultureOptions(
				this.searchConfiguration.psmCulture,
			);
		},

		selectedMainCategory() {
			if (this.searchConfiguration.category.length === 0) {
				return null;
			}
			let path = pathToRoot(
				this.$store.getters['category/byId'],
				this.searchConfiguration.category.slice(0),
			);
			const category = this.$store.getters['category/mainCategoryFromIds'](path);
			return category ? category.data.mainCategoryKey : null;
		},

		showSubstanceArea() {
			return (
				this.isAnyCategorySelected(
					'plant-protection-agents',
					'cleaning-disinfection-agents',
					'antiparasitica',
				) && this.showActiveAgent
			);
		},

		showComponentArea() {
			return (
				this.isAnyCategorySelected(
					'fertilisers',
					'plant-strengthening-agents',
					'animal-feed',
				) && this.showComponents
			);
		},

		showAnimalKindsArea() {
			return this.isListSwiss();
		},

		showPsmCultureArea() {
			return this.isListSwiss();
		},

		showCategoryArea() {
			return !this.showHobbyGardenArea && !this.$store.state.search.hideCategorySelection;
		},

		showHobbyGardenArea() {
			return this.isHobbyGardenList(this.searchConfiguration.list);
		},

		isEuMode() {
			return this.$store.state.search.euMode;
		},

		categoryFilter() {
			return this.$store.getters['search/getCategoryFilter'](this.searchConfiguration.list);
		},

		componentRootFilter() {
			const componentRoot = this.$store.getters['component/rootByMainCategory'](
				this.selectedMainCategory,
			);
			const ret = [];

			if (componentRoot) {
				ret.push(componentRoot.id);
			}

			return ret;
		},
	},
	validations: {
		searchConfiguration: {
			list: {
				required,
			},
		},
	},

	watch: {
		'searchConfiguration.list'(newVal, oldVal) {
			if (newVal === oldVal) {
				return;
			}
			this.setAdditionalListFilters();
			this.handleChangedFilter();
		},
		'searchConfiguration.standard'(newVal, oldVal) {
			if (newVal === oldVal) {
				return;
			}
			this.handleChangedFilter();
		},
		'searchConfiguration.substance'(newVal, oldVal) {
			if (newVal === oldVal) {
				return;
			}
			this.handleChangedFilter();
		},
		'searchConfiguration.hobbygarden'(newVal, oldVal) {
			if (newVal === oldVal) {
				return;
			}
			this.handleChangedFilter();
		},
		'searchConfiguration.cultureDetailDescription'(newVal, oldVal) {
			if (newVal === oldVal) {
				return;
			}
			this.handleChangedFilter();
		},
		'searchConfiguration.pestOrganism'(newVal, oldVal) {
			if (newVal === oldVal) {
				return;
			}
			this.handleChangedFilter();
		},
		'searchConfiguration.applicationEdited'(newVal, oldVal) {
			if (newVal === oldVal) {
				return;
			}
			this.handleChangedFilter();
		},
		'searchConfiguration.applicationParasites'(newVal, oldVal) {
			if (newVal === oldVal) {
				return;
			}
			this.handleChangedFilter();
		},
		'searchConfiguration.animalKinds'(newVal, oldVal) {
			if (newVal === oldVal) {
				return;
			}
			this.handleChangedFilter();
		},
		'searchConfiguration.psmCulture'(newVal, oldVal) {
			if (newVal === oldVal) {
				return;
			}
			this.handleChangedFilter();
		},

		productTitleSelected(nv, ov) {
			if (nv === ov) {
				return;
			}

			this.productTitleSelected = nv || '';
			this.searchConfiguration.productTitle = nv || '';
			this.handleChangedFilter();
		},

		distributorSelected(nv, ov) {
			if (nv === ov) {
				return;
			}
			if (!nv) nv = { name: '' };

			this.distributorSelected = nv;
			this.searchConfiguration.distributor =
				typeof nv === 'object' && nv.id ? nv.id : nv.name || '';
			this.handleChangedFilter();
		},

		showCultureDetailDescription() {
			if (!this.showCultureDetailDescription)
				this.searchConfiguration.cultureDetailDescription = '';
		},

		showPestOrganism() {
			if (!this.showPestOrganism) this.searchConfiguration.pestOrganism = '';
		},

		showApplicationEdited() {
			if (!this.showApplicationEdited) this.searchConfiguration.applicationEdited = '';
		},

		showApplicationParasites() {
			if (!this.showApplicationParasites) this.searchConfiguration.applicationParasites = '';
		},

		showComponentArea() {
			if (!this.showComponentArea) this.searchConfiguration.component = [];
		},

		showAnimalKindsArea() {
			if (!this.showAnimalKindsArea) this.searchConfiguration.animalKinds = [];
		},

		showPsmCultureArea() {
			if (!this.showPsmCultureArea) this.searchConfiguration.psmCulture = [];
		},

		showHobbyGardenArea() {
			if (!this.showHobbyGardenArea) this.searchConfiguration.hobbygarden = [];
		},

		showSubstanceArea() {
			if (!this.showSubstanceArea) this.searchConfiguration.substance = null;
		},

		showCategoryArea() {
			if (!this.showCategoryArea) this.searchConfiguration.category = [];
		},

		showStandardFilter() {
			if (!this.showStandardFilter) this.searchConfiguration.standard = '';
		},
	},

	async created() {
		let wait = [];
		wait.push(SearchOptionsProvider.initialize());
		if (
			this.$store.state.search.mainCategoryFilter &&
			this.$store.state.search.mainCategoryFilter.length
		) {
			wait.push(
				this.$store.dispatch('category/ensureRootsLoaded', {
					type: 'category',
					doNotLoadChildren: true,
				}),
			);
		}
		await Promise.all(wait);
		if (this.$store.state.search.preselectBioStandard) {
			this.searchConfiguration.standard = this.$store.state.search.preselectBioStandard;
		}
		this.$nextTick(this.initializeSearchConfiguration());
		if (!this.localeOptions.find((e) => e.value === this.selectedLocale)) {
			this.selectedLocale = this.localeOptions[0].value;
		}
		this.handleChangedFilter = debounce(() => {
			this.$store.commit('searchUi/setParams', this.searchConfiguration);
			if (this.$store.state.search.activePageNum !== 1) {
				this.$store.commit('search/setActivePageNum', 1);
				this.$store.commit('search/setStartItem', 0);
			}
		}, 500);
		this.initialized = true;
	},

	methods: {
		getSearchConfiguration(searchConfiguration) {
			return getBackendSearchConfiguration(
				searchConfiguration,
				this.$store.getters['search/getBackendParameters'](),
			);
		},

		async getProductTitles(query) {
			if (!query || query.length < 2 || query.replace(/[^\d\w ]+/gu, '').length < 2) {
				return;
			}
			const backendSearchConfiguration = this.getSearchConfiguration(
				JSON.parse(JSON.stringify(this.searchConfiguration)),
			);
			let params = {
				value: query,
				type: 'product',
				searchConfiguration: backendSearchConfiguration,
			};
			let res = await autocomplete(params);
			if (res && res.length) res = res.map((item) => item.field);
			this.productTitleData = res || [];
			this.searchConfiguration.productTitleData = this.productTitleData;
		},

		async getDistributors(query) {
			if (!query || query.length < 2 || query.replace(/[^\d\w ]+/gu, '').length < 2) {
				return;
			}
			const backendSearchConfiguration = this.getSearchConfiguration(
				JSON.parse(JSON.stringify(this.searchConfiguration)),
			);
			let res = await aggregateSearch(backendSearchConfiguration, {
				aggregateType: 'distributor',
			});
			if (res && res.length) res = res.map((item) => item.field);
			let newBuckets = [];
			if (res && res.aggregations && res.aggregations.ac && res.aggregations.ac.buckets) {
				let buckets = res.aggregations.ac.buckets;
				for (let i = 0; i < buckets.length; i++) {
					for (const idBucket of buckets[i].id.buckets) {
						newBuckets.push({
							name: buckets[i].key,
							id: idBucket.key,
						});
					}
				}
				res = res.aggregations.ac.buckets;
			}
			// newBuckets.sort((a, b) => {
			// 	const va = leven(a.name, query);
			// 	const vb = leven(b.name, query);
			// 	return va > vb ? 1 : -1;
			// });
			// if (newBuckets.length > 10) newBuckets = newBuckets.slice(0, 10);
			this.distributorData = newBuckets;
			this.searchConfiguration.distributorData = newBuckets;
		},

		executeSearch() {
			this.$v.$touch();
			if (this.$v.$invalid) {
				this.error = true;
				return;
			}

			this.error = false;
			const config = JSON.parse(JSON.stringify(this.searchConfiguration));
			config.selectedLocale = this.selectedLocale;
			this.$store.commit('search/setLastSearch', config);
			this.$emit('search');
		},

		initializeSearchConfiguration() {
			const lastSearch = JSON.parse(JSON.stringify(this.$store.getters['search/lastSearch']));
			if (lastSearch && typeof lastSearch === 'object') {
				this.searchConfiguration = lastSearch;
			} else if (this.listOptions.length > 0) {
				if (this.isEuMode) {
					this.searchConfiguration.list = this.$store.getters['list/baseListId'];
				} else {
					this.searchConfiguration.list =
						this.listOptions[0].value || this.$store.getters['list/baseListId'];
				}
			} else {
				setTimeout(() => {
					this.initializeSearchConfiguration();
				}, 200);
				return;
			}
			if (this.searchConfiguration.productTitle)
				this.productTitleSelected = this.searchConfiguration.productTitle;
			if (this.searchConfiguration.productTitleData)
				this.productTitleData = this.searchConfiguration.productTitleData;
			if (this.searchConfiguration.distributorData) {
				this.distributorData = this.searchConfiguration.distributorData;
				if (this.searchConfiguration.distributor) {
					let distributor = this.distributorData.find(
						(distributor) => distributor.id === this.searchConfiguration.distributor,
					);
					this.distributorSelected = distributor
						? distributor
						: { name: this.searchConfiguration.distributor };
				}
			} else {
				this.distributorSelected = { name: this.searchConfiguration.distributor };
			}
		},

		isAnyCategorySelected(...categories) {
			return categories.includes(this.selectedMainCategory);
		},

		isListSwiss() {
			const allList = SearchOptionsProvider.getListOptionsUnfiltered();
			const swizList = allList.filter((list) => list.data.path === 'CH');
			return swizList[0] !== undefined && swizList[0].id === this.searchConfiguration.list;
		},

		onChangeCategory() {
			this.searchConfiguration.substance = null;
			this.searchConfiguration.psmCulture = [];
			this.searchConfiguration.component = [];
			this.handleChangedFilter();
		},

		handlePsmOpen() {
			if (this.showPsmCultureArea)
				this.$store.dispatch(
					'field/loadFilteredPsmCultureOptions',
					this.searchConfiguration,
				);
		},

		handleSubstanceOpen() {
			if (this.showSubstanceArea)
				this.$store.dispatch(
					'field/loadFilteredSubstanceOptions',
					this.searchConfiguration,
				);
		},

		handleAnimalKindsOpen() {
			if (this.showAnimalKindsArea)
				this.$store.dispatch(
					'field/loadFilteredAnimalKindsOptions',
					this.searchConfiguration,
				);
		},

		resetSearch(onlySpecial) {
			if (!onlySpecial) {
				this.searchConfiguration.list = '';
				this.searchConfiguration.productTitle = '';
				this.searchConfiguration.distributor = '';
				this.searchConfiguration.standard = '';
				this.searchConfiguration.category = [];
				this.productTitleData = [];
				this.productTitleSelected = '';
				this.productTitleSearch = '';
				this.distributorData = [];
				this.distributorSelected = { name: '' };
				this.distributorSearch = '';
				this.searchConfiguration.searchHistory = false;
				this.searchConfiguration.searchHistoryDate = '';
				if (this.listOptions.length > 0) {
					this.searchConfiguration.list = this.listOptions[0].value;
				}
			}
			this.searchConfiguration.list = '';
			this.searchConfiguration.productTitle = '';
			this.searchConfiguration.distributor = '';
			this.searchConfiguration.standard = '';
			this.searchConfiguration.category = [];
			this.productTitleData = [];
			this.productTitleSelected = '';
			this.productTitleSearch = '';
			this.distributorData = [];
			this.distributorSelected = { name: '' };
			this.distributorSearch = '';
			this.searchConfiguration.searchHistory = false;
			this.searchConfiguration.searchHistoryDate = '';
			if (this.listOptions.length > 0) {
				this.searchConfiguration.list = this.listOptions[0].value;
			}
			this.searchConfiguration.substance = null;
			this.searchConfiguration.hobbygarden = [];
			this.searchConfiguration.component = [];
			this.searchConfiguration.animalKinds = [];
			this.searchConfiguration.psmCulture = [];
			this.searchConfiguration.cultureDetailDescription = '';
			this.searchConfiguration.pestOrganism = '';
			this.searchConfiguration.applicationEdited = '';
			this.searchConfiguration.applicationParasites = '';

			this.$store.commit('search/resetSearch');
		},

		isHobbyGardenList(listId) {
			const list = this.$store.getters['list/byId'](listId);
			if (!list) {
				return false;
			}
			return list.data.targetAudience === 'hobby';
		},

		updateSearchList() {
			if (!this.searchConfiguration) return;
			this.searchConfiguration.hobbygarden = [];
			this.searchConfiguration.standard = '';
			this.searchConfiguration.category = [];
			this.$store.commit(
				'search/setFallbackLocale',
				this.$store.getters['list/fallbackLocaleByListId'](this.searchConfiguration.list),
			);
			this.setAdditionalListFilters();
		},

		isFieldDisplayed(fieldName) {
			return (
				!this.searchConfiguration.searchHistory &&
				!!this.$store.state.search.pubSearchFields.find((field) => field === fieldName)
			);
		},

		setAdditionalListFilters() {
			this.$store.dispatch('search/setAdditionalListFilters', this.searchConfiguration.list);
		},

		setProductTitle(value) {
			this.productTitleSelected = value || '';
		},

		setDistributorSelected(value) {
			if (typeof value === 'string') value = { name: value };
			this.distributorSelected = value || { name: '' };
		},

		getDistbutorLabelFromObject(item) {
			return item.name;
		},

		showAgbModal() {
			this.$refs.agbModal.show();
		},

		onClickSearchHistory(e) {
			if (e) {
				this.resetSearch(true);
				this.searchConfiguration.searchHistory = true;
			} else {
				this.searchConfiguration.searchHistoryDate = '';
			}
		},
	},
};
</script>
