<template>
	<div>
		<b-button
			@click.prevent="exportResults()"
			variant="primary"
			class="mr-2"
			:disabled="hasJobQueued || !ready"
		>
			<span v-if="!working">
				{{ $t('searchForm.printReport.buttonTitle') }}
			</span>
			<span v-else>
				<b-spinner small label="Working"></b-spinner>
				<span> {{ $t('searchForm.printReport.timeEstimate') }}: {{ etaLabel }} </span>
				<span v-show="positionLabel > 1">
					{{ $t('searchForm.printReport.queuePosition') }}: {{ positionLabel }}
				</span>
			</span>
		</b-button>
	</div>
</template>

<script>
import { getBackendSearchConfiguration } from '@/util/search';
import humanizeDuration from 'humanize-duration';
import { fieldToLocale } from '@/util/locale';

export default {
	name: 'PdfQueue',

	props: {
		reportType: {
			type: String,
			required: true,
		},
		ready: {
			type: Boolean,
			required: true,
		},
	},

	data() {
		return {
			working: false,
		};
	},

	computed: {
		hasJobQueued() {
			return this.$store.getters['pdf/hasJobQueued'];
		},
		positionLabel() {
			if (!this.hasJobQueued) return 0;
			let pos = this.$store.state.pdf.queuePosition;
			return pos + 1;
		},
		etaLabel() {
			let eta = this.$store.state.pdf.queueEta;
			if (eta < 1000) {
				return '< 10 sec';
			} else {
				return humanizeDuration(eta);
			}
		},
	},

	methods: {
		async getFileName(listId, language) {
			let fileName = this.$t('searchForm.printReport.fileName', language);
			const list = this.$store.getters['list/byId'](listId);
			await this.$store.dispatch('country/loadAll', { ifEmpty: true });
			const country = this.$store.getters['country/byId'](list.data.country);
			const countryLabel = country
				? country.data.countrycode
				: fieldToLocale(list.data.title);
			return `${fileName}_${countryLabel}.pdf`;
		},
		async exportResults() {
			try {
				this.working = true;
				let lastSearch = JSON.parse(
					JSON.stringify(this.$store.getters['search/lastSearch']),
				);
				if (!lastSearch) return;
				lastSearch = getBackendSearchConfiguration(
					lastSearch,
					this.$store.getters['search/getBackendParameters'](),
				);
				let params = {
					searchConfiguration: lastSearch,
					listId: lastSearch.list,
					language: this.$store.state.search.selectedLocale,
					standard: lastSearch.standard[0],
					reportType: this.reportType, //'printreportpub'
				};
				const fileName = await this.getFileName(params.listId, params.language);
				await this.$store.dispatch('pdf/runQueue', {
					action: 'printReportFromResults',
					params,
					fileName,
				});
			} catch (err) {
				console.error(err);
			} finally {
				this.working = false;
			}
		},
	},
};
</script>
