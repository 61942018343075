<template>
	<div />
</template>

<script>
export default {
	name: 'NotificationBar',

	computed: {
		notificationsToShow() {
			return this.$store.getters['notification/getNotShown'];
		},
	},

	watch: {
		notificationsToShow() {
			this.showStoreEvent();
		},
	},

	created() {
		this.showStoreEvent();
	},

	methods: {
		showStoreEvent() {
			let e = this.notificationsToShow[0];
			if (e) {
				this.addNotification(e.type, e.title, e.body);
				this.$store.commit('notification/shiftToShow');
			}
		},
		addNotification(variant, title, body) {
			const tmp = { error: 'danger', message: 'primary' }[variant];
			variant = tmp || variant;
			if (!body) {
				body = title;
				title = '';
			}
			const isStatus = !['danger', 'warning'].includes(variant);
			this.$bvToast.toast(body, {
				title,
				variant,
				toaster: 'b-toaster-bottom-full',
				autoHideDelay: variant === 'danger' ? 10000 : 5000,
				isStatus,
			});
		},
	},
};
</script>
