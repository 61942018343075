<template>
	<b-jumbotron :header="$t('maintenance.header')" class="m-5">
		<p>{{ $t('maintenance.text') }}</p>
	</b-jumbotron>
</template>

<script>
export default {
	name: 'MaintenanceLayout',
};
</script>
