<template>
	<div>
		<div v-if="listCount > 0">
			{{ $t('results.showEntries') }} {{ firstItemNum }} - {{ lastItemNum }} ({{
				$t('results.of')
			}}
			{{ listCount }})
		</div>
		<b-row>
			<b-col md="3">
				<b-form-group :label="$t('results.perPage')" class="mt-2">
					<b-select
						:options="paginationOptions"
						v-model="perPage"
						@change="emitPerPageChange"
					/>
				</b-form-group>
			</b-col>
			<b-col md="3">
				<b-form-group :label="$t('results.page')" class="mt-2">
					<b-select :options="pages" :value="pageNum" @change="(e) => setPage(e)" />
				</b-form-group>
			</b-col>
		</b-row>
		<b-button-group>
			<b-button
				variant="outline-primary"
				@click.prevent="navigatePage(-1)"
				v-if="pageNum > 1"
			>
				&lt; {{ $t('results.previousPage') }}
			</b-button>
			<b-button
				variant="outline-primary"
				@click.prevent="navigatePage(1)"
				v-if="pageNum < pageCount"
			>
				{{ $t('results.nextPage') }} &gt;
			</b-button>
		</b-button-group>
	</div>
</template>

<script>
import * as Pagination from '@/util/pagination';

export default {
	name: 'Paginator',

	props: {
		paginationPrefix: {
			type: String,
			required: true,
		},
	},

	data() {
		return {
			startItem: 0,
		};
	},

	computed: {
		listCount() {
			return this.$store.state[this.paginationPrefix].searchCount;
		},

		paginationOptions() {
			return Pagination.PAGINATION_OPTIONS;
		},

		pageCount() {
			return Pagination.getPageCount(this.listCount, this.perPage);
		},

		firstItemNum() {
			return Pagination.getFirstItemIndex(this.startItem);
		},

		lastItemNum() {
			return Pagination.getLastItemIndex(
				this.startItem,
				this.$store.state.search.searchResults,
			);
		},

		pages() {
			return Pagination.getPages(this.pageCount);
		},

		perPage: {
			get() {
				return Pagination.getPerPage(this.paginationPrefix);
			},
			set(v) {
				Pagination.setPerPage(this.paginationPrefix, v);
			},
		},

		pageNum: {
			get() {
				return Pagination.getActivePageNum(this.paginationPrefix);
			},
			set(v) {
				Pagination.setActivePageNum(this.paginationPrefix, v);
			},
		},
	},

	watch: {
		perPage() {
			this.setPage(1);
		},
	},

	methods: {
		emitPaginationChange() {
			this.$emit('pagination-updated', this.startItem);
		},

		emitPerPageChange(nv) {
			this.$emit('input', nv);
		},

		setPage(n) {
			this.startItem = (n - 1) * this.perPage;
			this.pageNum = n;

			this.emitPaginationChange();
		},

		navigatePage(op) {
			const newPage = Math.min(Math.max(this.pageNum + op, 0), this.pageCount);
			this.setPage(newPage);
		},

		isActivePage(n) {
			return this.pageNum === n;
		},

		getActivePageButtonStyle(n) {
			return this.isActivePage(n) ? 'primary' : 'outline-primary';
		},
	},
};
</script>
