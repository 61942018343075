const state = {
	maintenanceMode: false,
};

const getters = {
	getMaintenanceMode: (state) => {
		return state.maintenanceMode;
	},
};

const actions = {};

const mutations = {
	setIsMaintenance(state) {
		state.maintenanceMode = true;
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
