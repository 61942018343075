<template>
	<layout-maintenance v-if="isMaintenance" />
	<div v-else id="app">
		<Notifications />
		<menu-bar />
		<div style="clear: both">
			<router-view />
		</div>
	</div>
</template>

<script>
import Notifications from '@/components/Notifications';
import LayoutMaintenance from '@/components/maintenance.vue';
import MenuBar from './components/MenuBar.vue';

export default {
	name: 'App',
	components: {
		MenuBar,
		Notifications,
		LayoutMaintenance,
	},
	computed: {
		hasTosAccepted() {
			return this.$store.getters['auth/user/hasTosAccepted'];
		},
		route() {
			return this.$route.name;
		},
		showLogin() {
			return this.$store.getters['search/usePaidUserUi'];
		},
		isMaintenance() {
			return this.$store.getters['base/getMaintenanceMode'];
		},
	},
	created() {
		console.log('APP', this);
	},
	mounted() {
		this.checkLogin();
	},
	methods: {
		async checkLogin() {
			if (this.$route.query.logmein === null) {
				// urlparam logmein exists (without value) #/?logmein
				// will not run twice if already triggered, needed here because we're awaiting it
				await this.$store.dispatch('auth/token/initApp');
				if (this.isLoggedIn) {
					let { query } = this.$route;
					delete query.logmein;
					let url = this.$router.resolve({ name: 'PublicSearch', query }).href;
					history.pushState({}, '', url);
				} else {
					this.$store.dispatch('auth/token/authenticate');
				}
			}
		},
	},
};
</script>

<style lang="scss">
#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: #2c3e50;
}
</style>
