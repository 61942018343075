export const backendUrl = process.env.VUE_APP_BASE_URL || 'https://demoapi.ecodb.de/bml';
export const NODE_ENV_PRODUCTION = process.env.NODE_ENV === 'production';
export const CommitSha = process.env.VUE_APP_COMMIT_SHA || 'dev';
export const LogUrl = process.env.VUE_APP_LOG_URL || 'https://demostatic.ecodb.de/csp/';
export const EnableLogin = !!process.env.VUE_APP_ENABLE_LOGIN;

let tmp = backendUrl.replace(/\/$/, '');
export const DOMAIN = tmp.substr(tmp.lastIndexOf('/') + 1);

export const KeycloakInfo = process.env.VUE_APP_KEYCLOAK_INFO
	? JSON.parse(process.env.VUE_APP_KEYCLOAK_INFO)
	: null;

export function parseEnv(store) {
	let mountElement = '#app';
	const configElement = document.getElementById('search-configuration');
	if (configElement) {
		let config = null;
		try {
			config = JSON.parse(configElement.innerHTML);
			if (config.readFromLocalStorage) {
				config = JSON.parse(localStorage.getItem('bml_pub_config') || '{}');
			}
		} catch {}
		if (!config) config = {};
		store.commit('search/setDefaultConfig', config);

		if (config.mountElement) {
			mountElement = config.mountElement;
		}
	} else {
		store.commit('search/setDefaultConfig');
	}
	return mountElement;
}
