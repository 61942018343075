<template>
	<b-card :title="$t('singleResult.history.cardTitle')" style="margin-bottom: 2em">
		<b-row>
			<b-col>
				<table class="historyTable">
					<thead>
						<tr>
							<th>{{ $t('singleResult.history.table.begin') }}</th>
							<th>{{ $t('singleResult.history.table.end') }}</th>
							<th>{{ $t('singleResult.history.table.status') }}</th>
						</tr>
					</thead>
					<tbody>
						<tr
							v-for="(entry, i) of customHistory"
							:key="i"
							:class="{ active: entry.state, inactive: !entry.state }"
						>
							<td>{{ entry.begin | toDateFormat }}</td>
							<td>{{ entry.end | toDateFormat }}</td>
							<td>{{ entry.state ? '✔' : '✘' }}</td>
						</tr>
					</tbody>
				</table>
			</b-col>
			<b-col>
				<p>{{ $t('singleResult.history.titleHistorySince', [dateFormatted]) }}.</p>
				<p v-if="standardsModeAll">{{ $t('singleResult.history.availableAll') }}</p>
				<p v-else>{{ $t('singleResult.history.availableOne') }}</p>
				<ul>
					<li v-for="standard of standardsForDisplay" :key="standard.id">
						{{ standard.label }}
					</li>
				</ul>
				<p v-show="showMultiStandardHint">
					{{ $t('singleResult.history.availableSeeBelow') }}
				</p>
			</b-col>
		</b-row>
		<!-- <span>zum testen interne namen: {{ standardList.map(e => e.data.name) | fieldToLocaleList }}</span> -->
	</b-card>
</template>

<script>
import { calculateRelevantHistory } from '@/util/history.js';
import { fieldToLocale } from '@/util/locale';
import { toDateFormat } from '@/plugins/filters';

export default {
	name: 'HistoryTable',
	props: {
		productData: {
			type: Object,
			required: true,
			default: () => null,
		},
		listId: {
			type: String,
			required: true,
		},
		historyDate: {
			type: String,
			required: false,
		},
	},
	data() {
		return {};
	},
	computed: {
		mustHaveStandards() {
			return this.$store.getters['approvals/getMustHaveApprovals'](this.listId);
		},
		shouldHaveStandards() {
			return this.$store.getters['approvals/getRelevantApprovals'](this.listId);
		},
		customHistory() {
			if (!this.productData) return [];
			let history = calculateRelevantHistory(
				this.productData,
				this.mustHaveStandards,
				this.shouldHaveStandards,
			);
			if (this.historyDate) {
				history = history.filter((e) => !e.end || e.end >= this.historyDate);
			}
			return history;
		},
		standardList() {
			return this.mustHaveStandards.length
				? this.mustHaveStandards
				: this.shouldHaveStandards;
		},
		standardsModeAll() {
			return this.mustHaveStandards.length > 0;
		},
		standardsForDisplay() {
			return this.standardList
				.map((e) => ({
					id: e.id,
					label: fieldToLocale(e.data.publicName),
				}))
				.filter((e) => !!e.label);
		},
		showMultiStandardHint() {
			return !this.standardsModeAll && this.standardsForDisplay.length > 1;
		},
		dateFormatted() {
			return toDateFormat(this.historyDate);
		},
	},
	methods: {},
};
</script>
