import * as Common from './tree-common';

const state = {
	entries: [],
	counts: {},
	entityclassId: '',
};

const getters = {
	roots: (state) => {
		return state.entries.filter((c) => !c.data.parent);
	},
	byParent: (state) => (id) => {
		return Common.getEntryByParent(state.entries, id);
	},
	byId: (state) => (id) => {
		return state.entries.find((c) => c.id === id);
	},
	all: (state) => {
		return state.entries;
	},

	getLevel: (state, getters) => (id) => {
		return Common.getLevel(getters.roots, 0, id);
	},

	pathToRoot: (state) => (ids) => {
		return Common.pathToRoot(getters.byId(state), ids);
	},

	countById: (state) => (id) => {
		return state.counts[id] || 0;
	},
};

const actions = {
	loadEntitesById({ commit }, ids) {
		return Common.loadEntitesById({ commit }, ids);
	},

	loadParentWithChildren({ commit, state }, { children, type }) {
		return Common.loadParentWithChildren({ commit, state }, { children, type });
	},

	ensureRootsLoaded({ commit }, { type }) {
		return Common.ensureRootsLoaded({ commit }, { type });
	},

	loadCounts({ commit, state, rootGetters }) {
		return Common.loadCounts({ commit, state, rootGetters }, 'hobby');
	},
};

const mutations = {
	setEcId(state, { id }) {
		state.entityclassId = id;
	},

	setEntry(state, entryToSet) {
		Common.setEntry(state, entryToSet);
	},

	setEntries(state, entriesToSet) {
		Common.setEntries(state, entriesToSet);
	},

	setList(state, entries) {
		state.entries = entries;

		for (let entity of state.entries) {
			if (!entity.children) {
				entity.children = [];
			}

			entity.children = getters.byParent(state)(entity.id);
		}
	},

	setCounts(state, counts) {
		state.counts = counts;
	},

	resetCounts(state) {
		state.counts = {};
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
