import { get, post } from '@/util/request.js';
import { getToken } from '@/util/jwt-cache.js';

export async function listAccountsForUser() {
	let auth = getToken();
	if (!auth) return [];
	let list = await get('auth/domain/listaccounts', null);
	return list;
}

export async function registerWithDomainByInvite(inviteId, registrationName, token) {
	const payload = {
		inviteId,
		registrationName,
		userData: {},
		token,
	};
	let { account } = await post('auth/domain/register', payload);
	return account;
}

export async function registerWithDomain(registrationName, userData, token) {
	const payload = {
		registrationName,
		userData,
		token,
	};
	let { account } = await post('auth/domain/register', payload);
	return account;
}

export function refreshToken() {
	return post('auth/account/refreshtoken', null);
}

export function getAccountDetails() {
	return get('auth/account/getaccount', null);
}

export function unregisterAccount(account) {
	return post('auth/account/unregisterwithaccount', { account });
}

export function updateAccount(data) {
	return post('auth/account/updateaccount', data);
}

export function loginToDomain(token, useAccount) {
	let p = { token };
	if (useAccount) p.useAccount = useAccount;
	return post('auth/domain/login', p);
}
