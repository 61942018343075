import EventBus from './eventbus';

export function emitFormError($v) {
	if (process.env.NODE_ENV === 'production') return null; //only for dev
	let errors = getErrorList($v);
	let message = 'Problems with: \n\t';
	message += errors
		.map(({ path, test }) => {
			return `${path} - Failing test: ${test}`;
		})
		.join('\n\t');
	EventBus.$emit('message', message);
	console.log($v);
	return errors;
}

export function getErrorList($v) {
	let errors = [];
	function parseLayer(v, path) {
		Object.entries(v).forEach(([key, val]) => {
			if (key === '$each') parseLayer(val, `${path}.${key}`);
			if (key.substr(0, 1) === '$') return;
			if (val === false) {
				errors.push({ path, test: key });
			}
			if (typeof val === 'object') {
				parseLayer(val, `${path}.${key}`);
			}
		});
	}
	parseLayer($v, '');
	return errors;
}

export function scrollToFirstError() {
	const elems = document.querySelectorAll('.is-invalid');
	for (let elem of elems) {
		const domRect = elem.getBoundingClientRect();
		if (!domRect || !domRect.height) continue;
		window.scrollTo(
			domRect.left + document.documentElement.scrollLeft,
			domRect.top + document.documentElement.scrollTop,
		);
		break;
	}
}
