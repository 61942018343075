import { getEntityclassByExternalId, getEntitiesById } from '@/repository/system.js';
import { search } from '@/repository/system';

const state = {
	options: [],
	entityclassId: '',
};

const getters = {
	byId: (state) => (id) => {
		return state.options.find((r) => r.id === id);
	},

	all: (state) => {
		return state.options;
	},

	byTypeSorted: (state) => (type) => {
		return state.options
			.filter((e) => e.data.type === type && e.data.enabled)
			.sort((a, b) => {
				return a.data.order < b.data.order ? -1 : 1;
			});
	},
};

function findSelectValuesFromProduct(productData) {
	let elementIds = new Set();
	let iterateArray = (array) => {
		for (const listElement of array) {
			if (typeof listElement === 'object') {
				iterateObject(listElement, elementIds);
			} else if (Array.isArray(listElement)) {
				iterateArray(listElement, elementIds);
			}
		}
	};
	let iterateObject = (object) => {
		for (const [key, value] of Object.entries(object)) {
			if (key === '_risklabels') {
				Object.entries(value).forEach(([k, v]) => {
					if (v && k && k.length === 36) elementIds.add(k);
				});
			} else if (key === 'selectValue') {
				if (
					value &&
					typeof value === 'string' &&
					value.length === 36 &&
					!elementIds.has(value)
				)
					elementIds.add(value);
			} else if (Array.isArray(value)) {
				iterateArray(value, elementIds);
			} else if (typeof value === 'object' && value) {
				iterateObject(value, elementIds);
			}
		}
	};
	iterateObject(productData);
	return Array.from(elementIds.values());
}

const actions = {
	async loadAll({ state, commit }, { ifEmpty }) {
		if (ifEmpty && state.options.length > 0) {
			return;
		}
		if (!state.entityclassId) {
			let ec = await getEntityclassByExternalId('selectoptions');
			commit('setEcId', { id: ec.id });
		}
		const list = await search(state.entityclassId);
		commit('setOptions', list);
	},
	async ensureOptionsInCache({ commit, rootGetters }, productData) {
		let allIds = findSelectValuesFromProduct(productData);
		if (allIds.length === 0) return;
		let allOptionIds = rootGetters['field/getAllOptionIds'];
		let selectIds = allIds.filter((e) => !allOptionIds.includes(e));
		if (selectIds.length === 0) return;
		const entries = await getEntitiesById(selectIds);
		commit('setEntries', entries);
	},
};

const mutations = {
	setOptions(state, options) {
		state.options = options;
	},
	setEcId(state, { id }) {
		state.entityclassId = id;
	},
	setEntries(state, entriesToSet) {
		const entryLabel = 'options';
		for (const entryToSet of entriesToSet) {
			const entryIndex = state[entryLabel].findIndex((entry) => entry.id === entryToSet.id);
			if (entryIndex === -1) {
				state[entryLabel].push(entryToSet);
			}
		}
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
