import * as PdfRepo from '@/repository/pdf';
import { sleep } from '@/util/sleep';
import Moment from 'moment';
import JSZip from 'jszip';
import Store from '@/store/main.js';

const state = {
	cached: [],

	queuePosition: 0,
	queueEta: 0,
	ticketNumber: '',
	fileName: '',
	archiveWorking: false,
};

const getters = {
	hasJobQueued: (state) => {
		return !!state.ticketNumber || state.archiveWorking;
	},
};

function downloadPdf(pdf, fileName) {
	let link = document.createElement('a');
	link.download = fileName ? fileName : 'download.pdf';
	link.href = pdf;
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
}
function dataUrItoBlob(dataUri) {
	var binary = atob(dataUri.split(',')[1]);
	var mimeString = dataUri.split(',')[0].split(':')[1].split(';')[0];
	var array = [];
	for (var i = 0; i < binary.length; i++) {
		array.push(binary.charCodeAt(i));
	}
	return new Blob([new Uint8Array(array)], { type: mimeString });
};

const actions = {
	async getPdf({ state, commit }, { productId, listId, language, restrictStandards }) {
		let key = `${productId}_${listId}_${language}`;
		let existing = state.cached.find((e) => e.key === key);
		if (existing) {
			downloadPdf(existing.pdf);
			return;
		}
		let pdf = await PdfRepo.getPdf(productId, listId, language, restrictStandards);
		commit('addToCache', { key, pdf });
		downloadPdf(pdf);
	},

	async getPdfWithoutDownload(_, { productId, listId, language, restrictStandards }) {
		let pdf = await PdfRepo.getPdf(productId, listId, language, restrictStandards);
		return pdf;
	},

	async getArchiveFromPdfs(_, { pdfs, zipFileName }) {
		const zip = new JSZip();
		for (let i=0; i<pdfs.length; i++) {
			let blob = dataUrItoBlob(pdfs[i].pdf);//do not use fetch here because of possible data-uri csp violation
			zip.file(`${pdfs[i].fileName}`, blob);
		}
		let zipfile = await zip.generateAsync({ type: 'base64' });
		downloadPdf(`data:application/zip;base64,${zipfile}`, zipFileName);
	},

	printReportFromResults(_, { searchConfiguration, listId, language, standard }) {
		const dateTime = new Moment(new Date()).format('DD.MM.YYYY, H:mm');
		const pubListFilter = Store.state.search.listFilter;
		return PdfRepo.getPdfWithQueue('printreportpub', {
			listId,
			language,
			standard,
			searchConfiguration,
			dateTime,
			pubListFilter,
		});
	},

	getpdfandstatusqueue(_, ticketNumber) {
		return PdfRepo.getpdfandstatusqueue(ticketNumber);
	},

	async runQueue({ dispatch, commit }, { action, params, fileName }) {
		let { ticketNumber } = await dispatch(action, params);
		commit('setTicketNumberAndFilename', ticketNumber, fileName);
		let start = Date.now();
		let retries = 0;
		do {
			let { result, error, position, eta, status } = await dispatch('getpdfandstatusqueue', {
				ticketNumber,
			});
			if (status === 'expired') {
				if (retries < 5) {
					({ ticketNumber } = await dispatch(action, params));
					retries++;
				} else {
					throw new Error('too many retries');
				}
			}
			if (position && eta) commit('updateQueue', { position, eta });
			if (error || result) commit('resetQueue');
			if (error) {
				throw new Error(error);
			}
			if (result) {
				downloadPdf(result, fileName);
				return;
			}
			await sleep(2000);
		} while (Date.now() - start < 1000 * 60 * 60); //1h max
		commit('resetQueue');
		throw new Error('timeout on retrieving pdf. ticket-number: ' + ticketNumber);
	},

	async waitForTicket({ commit, dispatch, state }) {
		//smaller version used when an existing ticket is found
		let start = Date.now();
		const ticketNumber = state.ticketNumber;
		if (!ticketNumber) return;
		do {
			let { result, error, position, eta, status } = await dispatch('getpdfandstatusqueue', {
				ticketNumber,
			});
			if (status === 'expired') {
				commit('resetQueue');
				return;
			}
			if (position && eta) commit('updateQueue', { position, eta });
			if (error || result) commit('resetQueue');
			if (error) {
				throw new Error(error);
			}
			if (result) {
				downloadPdf(result, state.fileName);
				return;
			}
			await sleep(2000);
		} while (Date.now() - start < 1000 * 60 * 60); //1h max
		commit('resetQueue');
		throw new Error('timeout on retrieving pdf. ticket-number: ' + ticketNumber);
	},
};

const mutations = {
	addToCache(state, { key, pdf }) {
		state.cached.unshift({ key, pdf });
		if (state.cached.length > 100) state.cached.pop();
	},

	setTicketNumberAndFilename(state, ticketNumber, fileName) {
		state.ticketNumber = ticketNumber;
		state.fileName = fileName;
	},

	updateQueue(state, { position, eta }) {
		state.queuePosition = position;
		state.queueEta = eta;
	},

	resetQueue(state) {
		state.queuePosition = 0;
		state.queueEta = 0;
		state.ticketNumber = '';
	},

	setArchiveWorking(state, value) {
		state.archiveWorking = value;
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
