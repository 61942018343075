<template>
	<div>
		<b-card :header="$t('searchForm.printReport.cardTitle')">
			<b-card-text>
				{{ $t('searchForm.printReport.cardDescription') }}
			</b-card-text>
			<ul class="checklist">
				<li :class="{ valid: hasSearchResult }">
					{{ $t('searchForm.printReport.hasSearch') }}
				</li>
				<li :class="{ valid: lowAmountResults }">
					{{ $t('searchForm.printReport.exportLimitReached') }}
				</li>
				<li :class="{ valid: hasStandard }">
					{{ $t('searchForm.printReport.noStandardSelected') }}
				</li>
			</ul>
			<pdf-queue report-type="printreportpub" :ready="canStartPrintReport" />
		</b-card>
	</div>
</template>

<script>
import PdfQueue from './PdfQueue.vue';
import { getBackendSearchConfiguration } from '@/util/search';

export default {
	name: 'ReportTab',

	components: {
		PdfQueue,
	},

	computed: {
		hasSearchResult() {
			return this.$store.state.search.searchCount > 0;
		},
		lowAmountResults() {
			return this.$store.state.search.searchCount <= 300;
		},
		hasStandard() {
			if (!this.hasSearchResult) return false;
			let search = getBackendSearchConfiguration(
				this.$store.getters['search/lastSearch'],
				this.$store.getters['search/getBackendParameters'](),
			);
			return search.standard && search.standard.length > 0;
		},
		canStartPrintReport() {
			return !!(this.hasSearchResult && this.lowAmountResults && this.hasStandard);
		},
	},
};
</script>
