import { backendUrl, LogUrl } from './env.js';
import { getToken } from './jwt-cache';

function request(path, data, post) {
	if (!data) data = {};
	if (path.substr(0, 1) !== '/') {
		path = `/${path}`;
	}
	let url = backendUrl + path;
	let opts = {};
	opts.mode = 'cors';
	opts.cache = 'no-cache';
	opts.headers = { 'Content-Type': 'application/json' };
	opts.redirect = 'follow';
	let auth = getToken();
	if (auth) {
		opts.headers.Authorization = `Bearer ${auth}`;
	}
	if (!post) {
		opts.method = 'GET';
		if (typeof payload === 'undefined') {
			url += `?__payload=${JSON.stringify(data)}`;
		}
	} else {
		opts.method = 'POST';
		opts.body = JSON.stringify(data);
	}

	return global
		.fetch(url, opts)
		.then((res) => {
			if (res.status === 429) return Promise.reject(new Error('429 - Too Many Requests'));
			return res.json();
		})
		.then((json) => {
			if (!json.ok) {
				let error = new Error();
				error.message = json.message ? json.message : 'unknown error';
				if (json.details) error.message += `\n - ${json.details}`;
				if (json.previous) error.previous = json.previous;
				if (json.stack) error.servicestack = json.stack;
				error.code = json.code;
				error.reqId = json.id;
				throw error;
			}
			return json.data;
		});
}

export function get(path, data) {
	return request(path, data, false);
}

export function post(path, data) {
	return request(path, data, true);
}

export function log(data) {
	global.fetch(LogUrl, {
		method: 'POST',
		headers: {
			'Content-type': 'application/json',
		},
		body: JSON.stringify(data),
	});
}
